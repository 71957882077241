import React from "react";
import { useNavigate } from "react-router-dom";
import "./MainStartupPage.css"; // Add styles for your page

const MainStartupPage = () => {
  const navigate = useNavigate();

  return (
    <div className="main-startup-container">
      {/* Logo Section */}
      <div className="logo-container">
        {/* Replace the src with your logo's file path */}
        <img
          src="/logo.png" // Replace with your logo's actual path
          alt="Company Logo"
          className="logo"
        />
      </div>

      {/* Title */}
      <h1>Welcome to myDailyReminders</h1>
      <p>Manage your account or get started today!</p>

      {/* Navigation Buttons */}
      <div className="button-container">
        <button className="btn btn-login" onClick={() => navigate("/login")}>
          Login
        </button>
        <button className="btn btn-enroll" onClick={() => navigate("/signup")}>
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default MainStartupPage;
