import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./FetchResultsPage.css";

const FetchResultsPage = () => {
  const { operation, campaignName } = useParams(); // Capture route params
  const decodedCampaignName = decodeURIComponent(campaignName); // Decode URL params
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const hasFetched = useRef(false); // Prevent duplicate fetches

  useEffect(() => {
    // Prevent double-fetch during React Strict Mode
    if (hasFetched.current) return; // Exit if already fetched
    hasFetched.current = true; // Mark as fetched

    console.log("Fetching data for:", operation, decodedCampaignName);

    // Fetch data based on action
    axios
      .get(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        params: {
          operation: operation,
          userid: "milton",
          campaignName: decodedCampaignName,
        },
      }
     )
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setError("Failed to load data.");
        setLoading(false);
      });
  }, [operation, decodedCampaignName]); // Dependencies

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container">
      <h2>Results for: {decodedCampaignName}</h2>
      <table className="results-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Author</th>
            <th>Name</th>
            <th>Text</th>
            <th>URL</th>
            <th>Question</th>
            <th>Answer 1</th>
            <th>Answer 2</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((item) => (
              <tr key={item.campaignItemsId}>
                <td>{item.campaignItemsId}</td>
                <td>{item.campaignItemsType}</td>
                <td>{item.campaignItemsAuthor}</td>
                <td>{item.campaignItemsName}</td>
                <td>{item.campaignItemsText}</td>
                <td>
                  <a href={item.campaignItemsURL1} target="_blank" rel="noreferrer">
                    Link
                  </a>
                </td>
                <td>{item.campaignItemsQuestion}</td>
                <td>{item.campaignItemsAnswer1}</td>
                <td>{item.campaignItemsAnswer2}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FetchResultsPage;
