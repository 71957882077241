import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "./CommonStyles.css";

const PersonalRemindersPage = () => {
  const { campaignName } = useParams();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  const [cognitoUserId, setCognitoUserId] = useState(null);
  const [reminderItems, setReminderItems] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(campaignName || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newItem, setNewItem] = useState({
    campaignItemsType: "",
    campaignItemsAuthor: "",
    campaignItemsName: "",
    campaignItemsText: "",
    campaignItemsURL1: ""
  });

useEffect(() => {
  const idToken = localStorage.getItem("idToken");

  if (!idToken) {
    console.warn("🚨 No idToken found! Redirecting to login...");
    setError("User is not authenticated. Please log in.");
    setTimeout(() => navigate("/login"), 100);
    return;
  }

  try {
    const decodedToken = jwtDecode(idToken);

    if (decodedToken.exp * 1000 < Date.now()) {
      console.warn("🚨 Token expired. Redirecting to login...");
      localStorage.removeItem("idToken");
      setError("Session expired. Please log in again.");
      setTimeout(() => navigate("/login"), 100);
      return;
    }

    setCognitoUserId(decodedToken.sub);
    setAuthToken(idToken);
    fetchAllCampaigns(decodedToken.sub);
  } catch (error) {
    console.error("❌ Error decoding token:", error);
    setError("Authentication error. Please log in again.");
    localStorage.removeItem("idToken");
    setTimeout(() => navigate("/login"), 100);
  }
}, []);




  useEffect(() => {
    if (selectedCampaign) {
      fetchReminderItems(selectedCampaign);
    }
  }, [selectedCampaign, cognitoUserId]);

  const fetchAllCampaigns = async (userid) => {
    if (!userid) {
      setError("User is not authenticated.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          params: { operation: "fetch_all", userid },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          },
        }
      );

      if (response.data.message === "No campaigns found") {
        setCampaigns([]);
      } else {
        const data = Array.isArray(response.data) ? response.data : [response.data];
        setCampaigns(data);
      }
    } catch (err) {
      console.error("Error fetching campaigns:", err);
      setError("Failed to fetch campaigns.");
    } finally {
      setLoading(false);
    }
  };

  const fetchReminderItems = (campaignName) => {
    if (!cognitoUserId || !campaignName) {
      return;
    }

    setLoading(true);
    const payload = {
      operation: "fetch_campaign_items",
      userid: cognitoUserId,
      campaignName: campaignName,
    };

    axios
      .post("https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign", payload)
      .then((response) => {
        setReminderItems(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching items:", err);
        setError("Failed to load items.");
        setLoading(false);
      });
  };

  const handleAddItem = () => {
    if (!selectedCampaign) {
      setError("Please select a campaign first.");
      return;
    }
    const payload = {
      operation: "add_campaign_item",
      userid: cognitoUserId,
      campaignName: selectedCampaign,
      ...newItem,
    };

    axios
      .post("https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign", payload)
      .then(() => {
        fetchReminderItems(selectedCampaign);
        setNewItem({ campaignItemsType: "", campaignItemsAuthor: "", campaignItemsName: "", campaignItemsText: "", campaignItemsURL1: "" });
      })
      .catch((err) => {
        console.error("Error adding item:", err);
        setError("Failed to add item.");
      });
  };

  return (
    <div className="container">
      <h2 className="header">Manage Personal Reminders</h2>
      {error && <p className="error-message">{error}</p>}
      {loading && <p className="loading-text">Loading...</p>}

      <div className="form-group">
        <h3>Select a Campaign</h3>
        <select
          className="dropdown"
          value={selectedCampaign}
          onChange={(e) => setSelectedCampaign(e.target.value)}
        >
          <option value="">-- Select a Campaign --</option>
          {campaigns.map((campaign) => (
            <option key={campaign.campaignName} value={campaign.campaignName}>
              {campaign.campaignName.replace(`${cognitoUserId}_`, "")}
            </option>
          ))}
        </select>
      </div>

      {selectedCampaign && (
        <div className="card">
          <h3 className="sub-header">Items in {selectedCampaign}</h3>
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Author</th>
                  <th>Name</th>
                  <th>Text</th>
                  <th>URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" value={newItem.campaignItemsType} onChange={(e) => setNewItem({ ...newItem, campaignItemsType: e.target.value })} /></td>
                  <td><input type="text" value={newItem.campaignItemsAuthor} onChange={(e) => setNewItem({ ...newItem, campaignItemsAuthor: e.target.value })} /></td>
                  <td><input type="text" value={newItem.campaignItemsName} onChange={(e) => setNewItem({ ...newItem, campaignItemsName: e.target.value })} /></td>
                  <td><input type="text" value={newItem.campaignItemsText} onChange={(e) => setNewItem({ ...newItem, campaignItemsText: e.target.value })} /></td>
                  <td><input type="text" value={newItem.campaignItemsURL1} onChange={(e) => setNewItem({ ...newItem, campaignItemsURL1: e.target.value })} /></td>
                  <td><button onClick={handleAddItem}>Add</button></td>
                </tr>
                {reminderItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.campaignItemsType || "N/A"}</td>
                    <td>{item.campaignItemsAuthor || "N/A"}</td>
                    <td>{item.campaignItemsName || "N/A"}</td>
                    <td>{item.campaignItemsText || "N/A"}</td>
                    <td>{item.campaignItemsURL1 ? <a href={item.campaignItemsURL1} target="_blank" rel="noopener noreferrer">Link</a> : "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalRemindersPage;
