import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./CommonStyles.css"; 
import { useNavigate, useParams } from "react-router-dom";  

const BigQuestionPage = () => {
  const [loginUserId, setLoginUserId] = useState("");
  const [question, setQuestion] = useState(null);
  const [editedQuestionText, setEditedQuestionText] = useState(""); 
  const [partnerResponses, setPartnerResponses] = useState([]);
  const [partnerLoginId, setPartnerLoginId] = useState(""); 
  const [error, setError] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const navigate = useNavigate(); 
  const [campaignName, setCampaignName] = useState("");
  const [userId, setUserId] = useState("");  
  const [bigQuestionCampaigns, setBigQuestionCampaigns] = useState([]); 
  const { campaignName: urlCampaignName } = useParams(); // ✅ Get campaign from URL
  const [campaignEntry, setCampaignEntry] = useState(null);
  //const [campaignEntry, setCampaignEntry] = useState({});
  const [partnerEmail, setPartnerEmail] = useState("");

  useEffect(() => {
    handleAuthAndFetchData();
  }, []);

  const handleAuthAndFetchData = async () => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      setError("User is not authenticated. Please log in.");
      navigate("/login");
      return;
    }

    try {
      const decodedToken = jwtDecode(idToken);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("idToken");
        setError("Session expired. Please log in again.");
        navigate("/login");
        return;
      }

      const userId = decodedToken.sub;
      setUserId(userId);
      setLoginUserId(decodedToken["cognito:username"]);

      // ✅ Fetch campaigns and handle auto-selection
      await fetchAllCampaigns(userId);

    } catch (error) {
      console.error("❌ Error decoding token:", error);
      setError("Authentication error. Please log in again.");
      localStorage.removeItem("idToken");
      navigate("/login");
    }
  };



  // List Leader section
  const [showEditModal, setShowEditModal] = useState(false);
  const [editOptions, setEditOptions] = useState({
    listLeaderPeriod: "",
    listLeaderTime: "",
    listLeaderDayOfWeek: "",
    listLeaderHrsAfterAItoRun: "",
  });

  useEffect(() => {
    if (campaignEntry) {
      setEditOptions({
        listLeaderPeriod: campaignEntry.listLeaderPeriod || "",
        listLeaderTime: campaignEntry.listLeaderTime || "",
        listLeaderDayOfWeek: campaignEntry.listLeaderDayOfWeek || "",
        listLeaderHrsAfterAItoRun: campaignEntry.listLeaderHrsAfterAItoRun || "4",
      });
    }
  }, [campaignEntry]);









  // ✅ Fetch all campaigns and filter only "BigQuestion" campaigns
  const fetchAllCampaigns = async (userid) => {
    const idToken = localStorage.getItem("idToken");
    setError("");

    try {
      const response = await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "fetch_all",
          userid: userid,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      if (response.data && Array.isArray(response.data)) {
        const filteredCampaigns = response.data.filter(
          (c) => c.campaignListCampaignType === "BigQuestion"
        );

        setBigQuestionCampaigns(filteredCampaigns);

        // ✅ Auto-select if only one campaign exists
        if (filteredCampaigns.length === 1) {
          const onlyCampaign = filteredCampaigns[0].campaignName;
          setCampaignName(onlyCampaign);
          fetchCurrentQuestion(userid, onlyCampaign);
          setCampaignEntry(filteredCampaigns[0]);  // ✅ Store campaign details

        }

        // ✅ Auto-select from URL if provided
        if (urlCampaignName) {
          const matchedCampaign = filteredCampaigns.find(
            (c) => c.campaignName === urlCampaignName
          );
          if (matchedCampaign) {
            setCampaignName(urlCampaignName);
            fetchCurrentQuestion(userid, urlCampaignName);
            setCampaignEntry(matchedCampaign);  // ✅ Store campaign details
          }
        }
      }


      } catch (err) {
        console.error("❌ Error fetching campaigns:", err);

        // ✅ Handle expired token case
        if (err.response && err.response.data && err.response.data.message === "The incoming token has expired") {
          console.warn("🚨 Token expired. Redirecting to login...");
          localStorage.removeItem("idToken");
          setError("Session expired. Please log in again.");
          navigate("/login");
          return;
        }

        setError("Failed to fetch campaigns.");
      }
    };


  // ✅ Fetch the current question and partner responses
  const fetchCurrentQuestion = async (userId, campaign) => {
    try {
      const idToken = localStorage.getItem("idToken");
      const response = await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "read_and_compare_current_item_big_question",
          userid: userId,
          campaignName: campaign,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      const data = response.data;

      if (data) {
        setQuestion(data);
        setEditedQuestionText(data.Answer1 || ""); 

        if (data.partnerResponses) {
          setPartnerResponses(data.partnerResponses);
        } else {
          setPartnerResponses([]);
        }
      } else {
        setError("No data available.");
      }


      } catch (err) {
        console.error("❌ Error fetching campaigns:", err);

        // ✅ Handle expired token case
        if (err.response && err.response.data && err.response.data.message === "The incoming token has expired") {
          console.warn("🚨 Token expired. Redirecting to login...");
          localStorage.removeItem("idToken");
          setError("Session expired. Please log in again.");
          navigate("/login");
          return;
        }

        setError("Failed to fetch campaigns.");
      }
    };







  // ✅ Function to save the updated question text
  const handleSaveQuestionEdit = async () => {
    if (!editedQuestionText.trim()) {
      setError("Answer cannot be empty.");
      return;
    }

    try {
      const idToken = localStorage.getItem("idToken");
      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "update_campaign_item",
          userid: userId,
          campaignName,
          campaignItemsId: question.campaignItemsId, 
          Answer1: editedQuestionText, 
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      setInfoMessage("Answer updated successfully!");
      setQuestion((prev) => ({ ...prev, Answer1: editedQuestionText }));
      await fetchCurrentQuestion(userId, campaignName);


      } catch (err) {
        console.error("❌ Error fetching campaigns:", err);

        // ✅ Handle expired token case
        if (err.response && err.response.data && err.response.data.message === "The incoming token has expired") {
          console.warn("🚨 Token expired. Redirecting to login...");
          localStorage.removeItem("idToken");
          setError("Session expired. Please log in again.");
          navigate("/login");
          return;
        }

        setError("Failed to fetch campaigns.");
      }
    };


 // ✅ Function to add a partner
  const handleAddPartner = async () => {
    if (!partnerLoginId.trim()) {
      setError("Please enter a partner's login ID.");
      return;
    }

    try {
      const idToken = localStorage.getItem("idToken");
      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "update_campaign_partners_big_question",
          userid: userId,
          campaignName,
          partnerLoginId,
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      setInfoMessage(`Partner ${partnerLoginId} added successfully!`);
      setPartnerLoginId("");

    // ✅ Re-fetch the latest question and partner responses
    fetchCurrentQuestion(userId, campaignName);

      
    } catch (err) {
      console.error("Error adding partner:", err);
      //setError("Failed to add partner. Please try again.");


      // ✅ Pass the exact error message from the API response to the UI
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Failed to add partner. Please try again.");
      }
  

    }
  };



  // ✅ Function to invite a partner (placeholder for future implementation)
  //const handleInvitePartner = () => {
  //  alert("Invite Partner functionality coming soon!"); // Placeholder
  //};



//start

  const handleInvitePartner = async () => {
    // need form field for here
    if (!partnerEmail.trim()) {
      setError("Please enter a partner's email address.");
      return;
    }

    try {
      const idToken = localStorage.getItem("idToken");
      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "send_invite_email_to_partner",
          userid: userId,
          campaignName,
          partnerEmail,
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      setInfoMessage(`Partner ${partnerEmail} sent!`);
      setPartnerLoginId("");

    // ✅ Re-fetch the latest question and partner responses
    fetchCurrentQuestion(userId, campaignName);

      
    } catch (err) {
      console.error("Error inviting  partner:", err);
      //setError("Failed to invite partner. Please try again.");


      // ✅ Pass the exact error message from the API response to the UI
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else {
        setError("Failed to invite partner. Please try again.");
      }
  

    }
  };


//end








  const handleUpdateOptions = async () => {
    try {
      const idToken = localStorage.getItem("idToken");
      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
        operation: "update_list_item",
        userid: userId,
        campaignName,
        listLeaderPeriod: editOptions.listLeaderPeriod,
        listLeaderTime: editOptions.listLeaderTime,
        listLeaderDayOfWeek: editOptions.listLeaderDayOfWeek,
        listLeaderHrsAfterAItoRun: editOptions.listLeaderHrsAfterAItoRun,
      },
      { headers: { Authorization: `Bearer ${idToken}` } }
    );

    setInfoMessage("Big Question options updated successfully!");
    setShowEditModal(false);
    await fetchCurrentQuestion(userId, campaignName);
    await fetchAllCampaigns(userId);

  } catch (err) {
    console.error("Error updating options:", err);
    setError("Failed to update options.");
  }
};


  // Create 24-hour options with 12-hour display labels
  const hoursOptions = Array.from({ length: 24 }, (_, hour) => {
    const value = hour.toString().padStart(2, "0") + ":00"; // "00:00" to "23:00"
    const displayHour = hour % 12 === 0 ? 12 : hour % 12;
    const suffix = hour < 12 ? "AM" : "PM";
    const label = `${displayHour}:00 ${suffix}`; // "1:00 PM", etc.
    return { value, label };
  });









  return (
    <div className="container">
      <h2>the Big Question</h2>
      {error && <p className="error-message">{error}</p>}
      {infoMessage && <p className="success-message">{infoMessage}</p>}


      {/* ✅ Dropdown for selecting BigQuestion campaigns */}
      <div className="dropdown-container">
        <label>Select a BigQuestion Campaign:</label>
        <select
          value={campaignName}
          onChange={(e) => {
            const selectedName = e.target.value;
            setCampaignName(selectedName);
            fetchCurrentQuestion(userId, selectedName);

            // ✅ Set campaignEntry for selected campaign
            const matched = bigQuestionCampaigns.find(c => c.campaignName === selectedName);
            if (matched) {
              setCampaignEntry(matched);
            }
          }}




        >
          <option value="">-- Select Campaign --</option>
          {bigQuestionCampaigns.length > 0 ? (
            bigQuestionCampaigns.map((c) => {



       // ✅ Remove `userid_` from the start of the campaign name
              const displayName = c.campaignName.startsWith(`${userId}_`)
                ? c.campaignName.replace(`${userId}_`, "")
                : c.campaignName;

              return (
                <option key={c.campaignName} value={c.campaignName}>
                  {displayName}
                </option>

                );
            })

          ) : (
            <option disabled>Loading campaigns...</option>
          )}
        </select>
      </div>


      {question && (
        <div className="card">
          <h3>{question.campaignItemsText}</h3>
        </div>
      )}

      {question && (!question.aiReview || !question.aiReview.summary) && (
        <div className="card">
          <textarea
            className="edit-question-text"
            value={editedQuestionText}
            onChange={(e) => setEditedQuestionText(e.target.value)}
            rows="3"
            placeholder="Write your answer here..."
          />
          <button className="save-button" onClick={() => handleSaveQuestionEdit()}>
            Save Answer
          </button>
        </div>
      )}

      {partnerResponses.length > 0 ? (
        <div className="partner-answers">
          <h4>Responses:</h4>


          <table className="styled-table">
            <thead>
              <tr>
                <th>Partner</th>
                <th>Answer</th>
              </tr>
            </thead>


            <tbody>
              {partnerResponses.map((response, index) => {
                let displayAnswer;

                if (!response.Answer1 || response.Answer1.trim() === "") {
                  displayAnswer = "No response yet";
                } else if (!question.Answer1 || question.Answer1.trim() === "") {
                  displayAnswer = "Answer locked until you respond";
                } else {
                  displayAnswer = response.Answer1;
                }

                return (
                  <tr key={index}>
                    <td>{response.partnerLoginId || "Unknown"}</td>
                    <td>{displayAnswer}</td>
                  </tr>
                );
              })}
            </tbody>


          </table>
            {/* ✅ AI Summary Section - Display if available */}
            {question && question.aiReview && question.aiReview.summary ? (
              <div className="ai-summary">
                <h4>AI Summary:</h4>
                <p>{question.aiReview.summary}</p>
              </div>
            ) : null}
        </div>
      ) : (
        <p>No partner responses yet.</p>
      )}





        <div className="card">
          <h3>Manage Partners</h3>
          <table style={{ width: "100%", borderSpacing: "0.5rem" }}>
            <tbody>
              <tr>
                <td><label htmlFor="partnerLoginId">Add Partner, Please enter their Login ID:</label></td>
                <td>
                  <input
                    id="partnerLoginId"
                    type="text"
                    placeholder="e.g. partner123"
                    value={partnerLoginId}
                    onChange={(e) => setPartnerLoginId(e.target.value)}
                  />
                </td>
                <td>
                  <button onClick={handleAddPartner}>Add Partner</button>
                </td>
              </tr>
              <tr>
                <td><label htmlFor="partnerEmail">Invite a partner to join, Enter their Email:</label></td>
                <td>
                  <input
                    id="partnerEmail"
                    type="email"
                    placeholder="e.g. partner@example.com"
                    value={partnerEmail}
                    onChange={(e) => setPartnerEmail(e.target.value)}
                  />
                </td>
                <td>
                  <button onClick={handleInvitePartner}>Invite Partner</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>











        {/* List Leader Section */}
        <div className="card">
          <div className="button-group">
            {question && campaignEntry?.listLeader && (
            //{question && campaignEntry.listLeader && (
              <button className="edit-options-button" onClick={() => setShowEditModal(true)}>
                Edit Options
              </button>
            )}
          </div>
        </div>


        {showEditModal && (
          <div className="modal">
            <div className="modal-content">

              <div className="edit-options-container">
                <h3>Edit Big Question Options</h3>
                
                <label>How often to run: 
                  <select value={editOptions.listLeaderPeriod} onChange={(e) => setEditOptions({...editOptions, listLeaderPeriod: e.target.value})}>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="now">Now</option>
                  </select>
                </label>


                <label>What time of day to run:
                  <select
                    value={editOptions.listLeaderTime}
                    onChange={(e) => setEditOptions({ ...editOptions, listLeaderTime: e.target.value })}
                  >
                    <option value="">-- Select Time --</option>
                    {hoursOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label} (Central)
                      </option>
                    ))}
                  </select>
                </label>


                {editOptions.listLeaderPeriod === "weekly" && (
                  <label>Day of the week: 
                    <select value={editOptions.listLeaderDayOfWeek} onChange={(e) => setEditOptions({...editOptions, listLeaderDayOfWeek: e.target.value})}>
                      <option value="Sunday">Sunday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                    </select>
                  </label>
                )}

                <label>Hours after AI Review to start next question:
                  <input
                    type="number"
                    value={editOptions.listLeaderHrsAfterAItoRun}
                    onChange={(e) => setEditOptions({...editOptions, listLeaderHrsAfterAItoRun: e.target.value})}
                    min="0"
                  />
                </label>

                  <br />
                  {/* Read-only fields displaying current values */}
                  <div className="read-only-fields">
                    <h4>Current Settings</h4>
                    <p><strong>Period:</strong> {campaignEntry.listLeaderPeriod || "Not Set"}</p>
                    <p><strong>Last Run:</strong> {campaignEntry.listLeaderLastRun || "No Previous Runs"}</p>
                    <p><strong>Configured Time of Day:</strong> {campaignEntry.listLeaderTime || "Not Set"}</p>
                    <p><strong>Date Added as List Leader:</strong> {campaignEntry.listLeaderDateAdded || "Not Set"}</p>
                    <p><strong>Hours after AI to move to next question:</strong> {campaignEntry.listLeaderHrsAfterAItoRun || "Not Set"}</p>
                  </div>



                <div className="button-group">
                  <button className="save-button" onClick={handleUpdateOptions}>Save Changes</button>
                  <button className="cancel-button" onClick={() => setShowEditModal(false)}>Cancel</button>
                </div>
              </div>







            </div>

          </div>
        )}


      <button className="button back-button" onClick={() => navigate("/list-manager")}>
        Back to Campaigns
      </button>
    </div>
  );
};

export default BigQuestionPage;
