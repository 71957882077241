import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CognitoIdentityProviderClient, SignUpCommand } from "@aws-sdk/client-cognito-identity-provider";
import { jwtDecode } from "jwt-decode";
import "./CustomerManagerPage.css";

const CustomerManagerPage = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    loginUserId: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    partnerLoginId: "",
  });
  const [idToken, setIdToken] = useState(null);
  const [cognitoUserId, setCognitoUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [customerData, setCustomerData] = useState(null);


useEffect(() => {
  const idToken = localStorage.getItem("idToken");

  if (!idToken) {
    console.warn("🚨 No idToken found! Redirecting to login...");
    setError("User is not authenticated. Please log in.");
    setTimeout(() => navigate("/login"), 100);
    return;
  }

  try {
    const decodedToken = jwtDecode(idToken);

    if (decodedToken.exp * 1000 < Date.now()) {
      console.warn("🚨 Token expired. Redirecting to login...");
      localStorage.removeItem("idToken");
      setError("Session expired. Please log in again.");
      navigate("/login");
      return;
    }

    console.log("✅ Decoded Token:", decodedToken);
    setIdToken(idToken);
    setCognitoUserId(decodedToken.sub);
    fetchCustomerData(decodedToken.sub); // ✅ Only fetch data if authentication is valid
  } catch (error) {
    console.error("❌ Error decoding token:", error);
    setError("Authentication error. Please log in again.");
    localStorage.removeItem("idToken");
    setTimeout(() => navigate("/login"), 100);
  }
}, []);






  // Fetch customer data
 const fetchCustomerData = async (userid) => {
    setLoading(true);
    setError("");



    try {
        const response = await axios.get(
            "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
            {
                params: { operation: "fetch_customer", userid },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("idToken")}`,
                },
            }
        );

        console.log("API Response:", response.data); // Debugging log

        // Adjust to handle both object and array responses
        //const data = Array.isArray(response.data) ? response.data[0] : response.data;
        const data = response.data?.customer || null;


        if (data) {
            console.log("Fetched Customer Data:", data); // Debugging log
            setCustomerData(data);
            setForm((prevForm) => ({
                ...prevForm,
                loginUserId: data.loginUserId || "",
                email: data.email || "",
                firstName: data.firstName || "",
                lastName: data.lastName || "",
                phone: data.phone || "",
                partnerLoginId: data.partnerId || "",
            }));
        } else {
            throw new Error("Customer data is missing or invalid.");
        }
    } catch (err) {
        console.error("Error fetching customer data:", err);
        setError("Failed to fetch customer data.");
    } finally {
        setLoading(false);
    }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value || "" }));
  };

  const handleUpdateCustomer = async () => {
    if (!cognitoUserId) {
      setError("User is not authenticated.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "update_customer",
          userid: cognitoUserId,
          loginUserId: form.loginUserId,
          email: form.email,
          firstName: form.firstName,
          lastName: form.lastName,
          phone: form.phone,
          partnerLoginId: form.partnerLoginId || "",
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      alert("Customer details updated successfully!");
    } catch (err) {
      console.error("Error updating customer:", err);
      setError("Failed to update customer details.");
    } finally {
      setLoading(false);
    }
  };

  
  
  return (


      <div className="container">
        <h2>Customer Manager</h2>

        {idToken && customerData && (
          <div className="customer-details-section">
            <h3>Customer Details</h3>
            <table className="customer-form-table">
              <tbody>
                <tr>
                  <td><label>Login ID:</label></td>
                  <td>
                    <input
                      type="text"
                      name="loginUserId"
                      value={form.loginUserId}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label>Email:</label></td>
                  <td>
                    <input
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label>First Name:</label></td>
                  <td>
                    <input
                      type="text"
                      name="firstName"
                      value={form.firstName}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label>Last Name:</label></td>
                  <td>
                    <input
                      type="text"
                      name="lastName"
                      value={form.lastName}
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td><label>Phone:</label></td>
                  <td>
                    <input
                      type="text"
                      name="phone"
                      value={form.phone}
                      onChange={handleChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td>
                    <br/>
                    <button type="button" onClick={handleUpdateCustomer} disabled={loading}>
                      Update Customer
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {loading && <div>Loading...</div>}
        {error && <div className="error-message">{error}</div>}
      </div>

  );
};

export default CustomerManagerPage;
