import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Decode JWT to get user ID
import "./common.css"; // Ensure styles are applied

const CampaignCatalogPage = () => {
  const navigate = useNavigate();
  const [campaignCatalog, setCampaignCatalog] = useState([]);
  const [userCampaigns, setUserCampaigns] = useState([]);
  const [cognitoUserId, setCognitoUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showCampaignNameModal, setShowCampaignNameModal] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [customCampaignName, setCustomCampaignName] = useState(""); // Stores user input for Personal Reminders
  const [partnerLoginId, setPartnerLoginId] = useState(""); // Stores partner ID input for Favorites campaign
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showBigQuestionModal, setShowBigQuestionModal] = useState(false);
  const [isCommercialReminder, setIsCommercialReminder] = useState(false);
  const [longDescriptionHTML, setLongDescriptionHTML] = useState(null);



  useEffect(() => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      console.warn("🚨 No idToken found! Redirecting to login...");
      setError("User is not authenticated. Please log in.");
      navigate("/login");
      return;
    }

    try {
      const decodedToken = jwtDecode(idToken);
      console.log("✅ Decoded Token:", decodedToken);

      if (decodedToken.exp * 1000 < Date.now()) {
        console.warn("🚨 Token expired. Redirecting to login...");
        localStorage.removeItem("idToken");
        setError("Session expired. Please log in again.");
        navigate("/login");
        return;
      }

      setCognitoUserId(decodedToken.sub);
      fetchCampaignCatalog(decodedToken.sub);
      fetchUserCampaigns(decodedToken.sub);
    } catch (error) {
      console.error("❌ Error decoding token:", error);
      setError("Authentication error. Please log in again.");
      localStorage.removeItem("idToken");
      navigate("/login");
    }
  }, []);

  // Fetch all available campaigns from campaignCatalog
  const fetchCampaignCatalog = async (userid) => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          params: { operation: "fetch_all_catalog", userid },
          headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
        }
      );
      setCampaignCatalog(response.data || []);
    } catch (err) {
      handleAuthError(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the campaigns the user already has
  const fetchUserCampaigns = async (userid) => {
    try {
      const response = await axios.get(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          params: { operation: "fetch_all", userid },
          headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
        }
      );
      setUserCampaigns(response.data || []);
    } catch (err) {
      handleAuthError(err);
    }
  };

  // Handle unauthorized errors and expired tokens
  const handleAuthError = (err) => {
    console.error("❌ API Request Failed:", err);

    if (err.response && err.response.status === 401) {
      console.warn("🚨 Unauthorized! Redirecting to login...");
      localStorage.removeItem("idToken");
      setError("Session expired. Please log in again.");
      navigate("/login");
    } else {
      setError("An error occurred. Please try again.");
    }
  };

  // Check if a campaign is already in the user's list
  const isCampaignSelected = (realName) => {
    return userCampaigns.some((campaign) => campaign.campaignListRealCampaignName === realName);
  };

  // Handle campaign selection (Favorites & Personal Reminders show modals)
  const handleCampaignSelect = async (campaign) => {
    if (!cognitoUserId) {
      setError("User not authenticated.");
      return;
    }

    if (campaign.campaignCatalogType === "PersonalReminders") {
      setIsCommercialReminder(false);
      setShowCampaignNameModal(true);
      setSelectedCampaign(campaign);
      return;
    }

    //if (campaign.campaignCatalogName === "Favorites") {
    // this was the old code when working on cmmercial

    if (campaign.campaignCatalogType === "Favorites") {
      setShowPartnerModal(true);
      setSelectedCampaign(campaign);
      return;
    }

    if (campaign.campaignCatalogType === "BigQuestion") {
      await startBigQuestionCampaign(campaign);
      return;
    }


    // Commercial Reminders
    if (campaign.campaignCatalogType === "CommercialReminders") {
      setIsCommercialReminder(true);
      setSelectedCampaign(campaign);
      setCustomCampaignName(campaign.campaignCatalogName); // 👈 Pre-fill
      setShowCampaignNameModal(true);
      return;
    }


    await addCampaignToUserList(campaign.campaignCatalogName, campaign.campaignCatalogRealName, campaign.campaignCatalogType);
    navigate(`/item-manager/${campaign.campaignCatalogName}`);
  };




const startBigQuestionCampaign = async (campaign) => {
  if (!cognitoUserId) {
    setError("User not authenticated.");
    return;
  }

  setLoading(true);
  setError("");

  try {
    // ✅ Step 1: Start the Big Question campaign

    const realCampaignName = campaign.campaignCatalogRealName;

    await axios.post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        operation: "start_big_question_campaign",
        userid: cognitoUserId,
        loginUserId: cognitoUserId,
        campaignName: realCampaignName,
        campaignListCommMethod: "email",
        campaignListNextSeq: 1,
        campaignListSendWhen: "daily",
        campaignListRealCampaignName: realCampaignName,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
      }
    );

    //alert("Big Question campaign started successfully!");
    
    // ✅ Step 2: Redirect user to BigQuestionPage
    navigate(`/big-question/${realCampaignName}`);
  } catch (err) {
    console.error("Error starting Big Question campaign:", err);
    setError("Failed to start Big Question campaign. Please try again.");
  } finally {
    setLoading(false);
  }
};





const addCampaignToUserList = async (campaignName, realName, campaignType) => {
  const payload = {
    operation: "add_list_item",
    userid: cognitoUserId,
    campaignName,
    campaignListRealCampaignName: realName,
    campaignListCampaignType: campaignType,
    campaignListCommMethod: "email",
    campaignListSendWhen: "readNext",
    campaignListNextSeq: 1,
    campaignListReminderSchedule: "daily_8am",
    campaignListReminderDays: "7"
  };

  try {
    await axios.post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      payload,
      { headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` } }
    );

    fetchUserCampaigns(cognitoUserId); // Refresh user campaigns
  } catch (err) {
    handleAuthError(err);
  }
};






  // Function to start the "Favorites" campaign after user inputs a partner ID
const handleStartFavoritesCampaign = async () => {
  if (!cognitoUserId || !partnerLoginId.trim()) {
    setError("User and partner must be provided.");
    return;
  }

  setLoading(true);
  setError("");

  try {

    //step 0 - check for partner

    const partnerData = await fetchCustomerData(partnerLoginId);

    if (!partnerData) {
      setError("The partner has not yet enrolled at myDailyReminders.");
      return;
    }



    // ✅ Step 1: Start the Favorites campaign
    await axios.post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        operation: "start_favorites_campaign",
        userid: cognitoUserId,
        loginUserId: cognitoUserId,
        campaignName: `${cognitoUserId}_Favorites`,
        campaignListCommMethod: "email",
        campaignListNextSeq: 1,
        campaignListSendWhen: "daily",
        campaignListRealCampaignName: `${cognitoUserId}_Favorites`,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
      }
    );

    // ✅ Step 2: Add the partner to the campaign
    await handleAddPartner(`${cognitoUserId}_Favorites`);

    //alert("Favorites campaign started successfully!");
    fetchUserCampaigns(cognitoUserId);
    navigate(`/qa/${cognitoUserId}/${cognitoUserId}_Favorites`);
  } catch (err) {
    console.error("Error starting favorites campaign:", err);
    setError("Failed to start Favorites campaign. Please try again.");
  } finally {
    setLoading(false);
  }
};





const handleAddPartner = async (campaignName) => {
  if (!partnerLoginId || !partnerLoginId.trim()) {
    setError("Please enter a partner's login ID.");
    return;
  }

  setLoading(true);
  setError("");

  try {
    await axios.post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        operation: "update_campaign_partners",
        userid: cognitoUserId,
        campaignName,
        partnerLoginId,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
      }
    );

    //alert(`Partner ${partnerLoginId} added to ${campaignName}`);
    fetchUserCampaigns(cognitoUserId);
  } catch (err) {
    console.error("Error adding partner:", err);
    setError("Failed to add partner. Please try again.");
  } finally {
    setLoading(false);
  }
};


const fetchCustomerData = async (partnerLoginId) => {
  setLoading(true);
  setError("");

  try {
    const response = await axios.get(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        params: { operation: "fetch_customer_by_login", userid: partnerLoginId },
        headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
      }
    );

    return response.data; // ✅ Return the partner's data if found
  } catch (err) {
    console.error("Error fetching customer data:", err);
    return null; // ✅ Return null if not found
  } finally {
    setLoading(false);
  }
};

const handleReminderCampaignSubmit = async () => {
  if (!customCampaignName.trim()) {
    setError("Campaign name cannot be empty.");
    return;
  }

  let realName, campaignName, campaignType;

  if (isCommercialReminder) {
    realName = selectedCampaign.campaignCatalogRealName; // shared name
    campaignName = customCampaignName.trim(); // user-defined label
    campaignType = "CommercialReminders";
  } else {
    realName = `${cognitoUserId}_${customCampaignName.trim()}`;
    campaignName = customCampaignName.trim();
    campaignType = "PersonalReminders";
  }

  await addCampaignToUserList(campaignName, realName, campaignType);

  setShowCampaignNameModal(false);
  setCustomCampaignName("");

  navigate(`/item-manager/${campaignName}`);
};



return (

  <div className="container">
    <h2 className="header">Available Products</h2>
    {error && <p className="error-message">{error}</p>}
    {loading && <p className="loading-text">Loading...</p>}


    {/* Table for web only */}
    <div className="table-container">
      <table className="styled-table desktop-only">
        <thead>
          <tr>
            <th>Select</th>
            <th>Name/Description</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {campaignCatalog.map((campaign, index) => (
            <tr key={index}>
              <td>
                <input
                  type="radio"
                  checked={isCampaignSelected(campaign.campaignCatalogRealName)}
                  onChange={() => handleCampaignSelect(campaign)}
                  disabled={isCampaignSelected(campaign.campaignCatalogRealName)}
                />
              </td>
              

                <td>
                  <strong>{campaign.campaignCatalogName}</strong>

                  <div className="campaign-description">
                    {campaign.campaignCatalogShortDescription}
                  </div>

                  <div className="campaign-footer">
                    <button
                      className="info-button"
                      onClick={async () => {
                        const realName = campaign.campaignCatalogRealName;
                        const cleanName =
                          realName.startsWith("Favorites") || realName.includes("BigQuestion")
                            ? realName.replace(/^[^_]+_/, "")
                            : realName;
                        const html = await fetch(`/campaign-details/${cleanName}.html`).then(res => res.text());
                        setLongDescriptionHTML(html);
                      }}
                      title="More Info"
                    >
                      More Info
                    </button>

                    <div className="campaign-cost">
                      {campaign.campaignCatalogCost === "0" ? "Free" : `$${campaign.campaignCatalogCost}`}
                    </div>
                  </div>
                </td>





            </tr>
          ))}
        </tbody>
      </table>
    </div>



    {/* Table for mobile only */}

    <div className="mobile-only">
      {campaignCatalog.map((campaign, index) => (
        <div key={index} className="campaign-card">
          <div className="card-header">
            <div className="radio-wrapper">
              <input
                type="radio"
                checked={isCampaignSelected(campaign.campaignCatalogRealName)}
                onChange={() => handleCampaignSelect(campaign)}
                disabled={isCampaignSelected(campaign.campaignCatalogRealName)}
              />
            </div>
              <span className="campaign-title">{campaign.campaignCatalogName}</span>
          </div>


          <div className="campaign-description">
            {campaign.campaignCatalogShortDescription}
          </div>

          {/* Footer row with left/right split */}
          <div className="campaign-footer">
            <button
              className="info-button"
              onClick={async () => {
                const realName = campaign.campaignCatalogRealName;
                const cleanName =
                  realName.startsWith("Favorites") || realName.includes("BigQuestion")
                    ? realName.replace(/^[^_]+_/, "")
                    : realName;
                const html = await fetch(`/campaign-details/${cleanName}.html`).then(res => res.text());
                setLongDescriptionHTML(html);
              }}
              title="More Info"
            >
              More Info
            </button>
            <div className="campaign-cost">
              {campaign.campaignCatalogCost === "0" ? "Free" : `$${campaign.campaignCatalogCost}`}
            </div>
          </div>
        </div>



      ))}
    </div>

 





    {/* Personal Reminders Modal */}
    {showCampaignNameModal && (
      <div className="modal-overlay">
        <div className="modal">
          <h3>Enter Campaign Name</h3>
          <input
            type="text"
            value={customCampaignName}
            onChange={(e) => setCustomCampaignName(e.target.value)}
            placeholder="Enter campaign name"
          />
          <div className="button-group">
            <button className="button button-success" onClick={handleReminderCampaignSubmit}>Create Campaign</button>
            <button className="button button-danger" onClick={() => setShowCampaignNameModal(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}

    {/* Partner Entry Modal */}
    {showPartnerModal && (
      <div className="modal-overlay">
        <div className="modal">
          <h3>Enter Partner's Login ID</h3>
          <input
            type="text"
            placeholder="Partner Login ID"
            value={partnerLoginId}
            onChange={(e) => setPartnerLoginId(e.target.value)}
          />
          <div className="button-group">
            <button className="button button-success" onClick={handleStartFavoritesCampaign}>Start Favorites Campaign</button>
            <button className="button button-danger" onClick={() => setShowPartnerModal(false)}>Cancel</button>
          </div>
        </div>
      </div>
    )}



    {longDescriptionHTML && (
      <div className="modal-overlay" onClick={() => setLongDescriptionHTML(null)}>
        <div className="modal large-modal" onClick={(e) => e.stopPropagation()}>
          <div dangerouslySetInnerHTML={{ __html: longDescriptionHTML }} />
          <button className="button button-danger" onClick={() => setLongDescriptionHTML(null)}>Close</button>
        </div>
      </div>
    )}





  </div>





);
{/* end of return */}


};
export default CampaignCatalogPage;
