import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ListManagerPage = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cognitoUserId, setCognitoUserId] = useState(null);
  const [confirmDeleteItem, setConfirmDeleteItem] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);


  const [form, setForm] = useState({
    campaignName: "",
    campaignListCommMethod: "email",
    campaignListNextSeq: 1,
    campaignListReminderSchedule: "daily_8am",
    campaignListReminderDays: "7",
  });

  useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (!idToken) return navigate("/login");

    try {
      const decoded = jwtDecode(idToken);
      if (decoded.exp * 1000 < Date.now()) throw new Error("Token expired");
      setCognitoUserId(decoded.sub);
      fetchAllCampaigns(decoded.sub);
    } catch (e) {
      localStorage.removeItem("idToken");
      navigate("/login");
    }
  }, []);

  const fetchAllCampaigns = async (userid) => {
    setLoading(true);
    try {
      const res = await axios.get(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          params: { operation: "fetch_all", userid },
          headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
        }
      );
      setCampaigns(Array.isArray(res.data) ? res.data : [res.data]);
    } catch (err) {
      console.error(err);
      if (err.response?.data?.message === "The incoming token has expired") {
        localStorage.removeItem("idToken");
        navigate("/login");
      } else {
        setError("Failed to load campaigns");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    const item = campaigns[index];
    if (["Favorites", "BigQuestion"].includes(item.campaignListCampaignType)) return;
    const editable = {
      campaignName: item.campaignName,
      campaignListCommMethod: item.campaignListCommMethod,
      campaignListNextSeq: item.campaignListNextSeq,
      campaignListReminderSchedule: item.campaignListReminderSchedule || "",
      campaignListReminderDays: item.campaignListReminderDays || "7",
    };
    setEditIndex(index);
    setForm(editable);
    setEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: name === "campaignListNextSeq" ? parseInt(value, 10) || 0 : value,
    }));
  };

  const handleSubmit = async () => {
    const item = campaigns[editIndex];
    if (!cognitoUserId || !item) return;
    try {
      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "update_list_item",
          userid: cognitoUserId,
          campaignName: item.campaignName,
          campaignListCommMethod: form.campaignListCommMethod,
          campaignListNextSeq: form.campaignListNextSeq,
          campaignListReminderSchedule: form.campaignListReminderSchedule,
          campaignListReminderDays: form.campaignListReminderDays,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("idToken")}`,
          },
        }
      );
      fetchAllCampaigns(cognitoUserId);
      setEditing(false);
    } catch (err) {
      console.error("Update failed", err);
      if (err.response?.data?.message === "The incoming token has expired") {
        localStorage.removeItem("idToken");
        navigate("/login");
      } else {
        setError(err.response?.data?.error || "Update failed");
      }
    }
  };


const confirmAndRemove = async () => {
  if (!confirmDeleteItem || !cognitoUserId) return;
  try {
    const item = confirmDeleteItem;
    const type = item.campaignListCampaignType;
    const op = type === "Favorites"
      ? "remove_favorites_campaign"
      : type === "BigQuestion"
      ? "remove_big_question_campaign"
      : "delete_list_item";

    const res = await axios.post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      { operation: op, userid: cognitoUserId, campaignName: item.campaignName },
      { headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` } }
    );

    // ✅ Show success message if present
    if (res.data?.message) {
      setSuccessMessage(res.data.message);
      setTimeout(() => setSuccessMessage(null), 4000); // Hide after 4s
    }

    fetchAllCampaigns(cognitoUserId);
  } catch (err) {
    console.error("Delete failed", err);
    if (err.response?.data?.message === "The incoming token has expired") {
      localStorage.removeItem("idToken");
      navigate("/login");
    } else {
      setError(err.response?.data?.error || "Delete failed");
    }
  } finally {
    setConfirmDeleteItem(null);
  }
};






  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container">
      <h2>Manage Campaign Lists/Programs</h2>
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Comm Method</th>
            <th>Next Seq</th>
            <th>Send When</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((item, index) => {
            const cleanedName = item.campaignName.replace(new RegExp(`^${cognitoUserId}_`), "");
            const showSchedule = item.campaignListSendWhen === "readNext" && ["PersonalReminders", "CommercialReminders"].includes(item.campaignListCampaignType);
            const scheduleLabel = showSchedule
              ? `${item.campaignListReminderSchedule || ""} (${item.campaignListReminderDays || "7"} days/week)`
              : item.campaignListSendWhen;

            return (
              <tr key={index}>
                <td>{cleanedName}</td>
                <td>{item.campaignListCampaignType}</td>
                <td>{item.campaignListCommMethod}</td>
                <td>{item.campaignListNextSeq}</td>
                <td>{scheduleLabel}</td>
                <td>
                  {!["Favorites", "BigQuestion"].includes(item.campaignListCampaignType) && (
                    <button onClick={() => handleEdit(index)}>Edit</button>
                  )}
                  <button onClick={() => setConfirmDeleteItem(item)}>Remove</button>
                  <button onClick={() => {
                    let dest = "";
                    if (item.campaignListCampaignType === "Favorites")
                      dest = `/qa/${cognitoUserId}/${item.campaignName}`;
                    else if (["PersonalReminders", "CommercialReminders"].includes(item.campaignListCampaignType))
                      dest = `/item-manager/${encodeURIComponent(item.campaignName)}`;
                    else if (item.campaignListCampaignType === "BigQuestion")
                      dest = `/big-question/${item.campaignName}`;
                    else
                      dest = `/campaign/${encodeURIComponent(item.campaignName)}`;
                    navigate(dest);
                  }}>
                    Go to Campaign
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {confirmDeleteItem && (
        <div className="modal-overlay">
          <div className="modal">
            <br/>
            <p>
              Are you sure you want to delete{" "}
              <strong>
                {confirmDeleteItem.campaignName.replace(new RegExp(`^${cognitoUserId}_`), "")}
              </strong>?
            </p>
            <div className="modal-buttons">
              <button onClick={confirmAndRemove}>Yes, Delete</button>
              <button onClick={() => setConfirmDeleteItem(null)}>Cancel</button>
              <br/>
            </div>
          </div>
        </div>
      )}

      {editing && (
        <div>
          <h3>Edit Campaign</h3>
          <table className="form-table">
            <tbody>
              <tr>
                <td><label>Campaign Name:</label></td>
                <td>
                  <input type="text" name="campaignName" value={form.campaignName} readOnly />
                </td>
              </tr>
              <tr>
                <td><label>Comm Method:</label></td>
                <td>
                  <select name="campaignListCommMethod" value={form.campaignListCommMethod} onChange={handleChange}>
                    <option value="email">Email</option>
                    <option value="text">Text (not yet available)</option>
                    <option value="other">Other</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>Next Seq:</label></td>
                <td>
                  <input type="number" name="campaignListNextSeq" value={form.campaignListNextSeq} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td><label>Reminder Schedule:</label></td>
                <td>
                  <select name="campaignListReminderSchedule" value={form.campaignListReminderSchedule} onChange={handleChange}>
                    <option value="now">Now</option>
                    <option value="daily_8am">Daily 8 AM</option>
                    <option value="twice_day">Twice a Day</option>
                    <option value="every_2hrs">Every 2 Hours (8a–8p)</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><label>Reminder Days:</label></td>
                <td>
                  <select name="campaignListReminderDays" value={form.campaignListReminderDays} onChange={handleChange}>
                    <option value="5">5 Days</option>
                    <option value="7">7 Days</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <button onClick={handleSubmit}>Save Changes</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ListManagerPage;