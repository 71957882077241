import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./common.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false); // 👈 call this after a selection
  const [showMaintain, setShowMaintain] = useState(false);


  return (
    <nav className="navbar">
      <div className="navbar-header">
        <button className="hamburger" onClick={toggleMenu}>
          ☰
        </button>
        <div className="navbar-title">myDailyReminders</div>
      </div>
      <ul className={`nav-links ${isMenuOpen ? "show" : ""}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/campaign-catalog" onClick={closeMenu}>Catalog</Link></li>


<li className={`dropdown ${showMaintain ? 'open' : ''}`}>
  <span
    className="dropdown-toggle"
    onClick={() => setShowMaintain(!showMaintain)}
  >
    Maintain ▾
  </span>
  {showMaintain && (
    <ul className="dropdown-menu">
      <li><Link to="/list-manager" onClick={() => { closeMenu(); setShowMaintain(false); }}>Manage Lists</Link></li>
      <li><Link to="/item-manager" onClick={() => { closeMenu(); setShowMaintain(false); }}>Manage Items</Link></li>
      <li><Link to="/customer-manager" onClick={() => { closeMenu(); setShowMaintain(false); }}>Manage Customers</Link></li>
    </ul>
  )}
</li>




        <li><Link to="/qa/test-user/test-campaign" onClick={closeMenu}>Q&A Page</Link></li>
        <li><Link to="/personal-reminders" onClick={closeMenu}>Personal Reminders</Link></li>
        <li><Link to="/big-question" onClick={closeMenu}>Big Question</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
