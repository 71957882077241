import React, { useState } from "react";
import {
  CognitoIdentityProviderClient,
  SignUpCommand,
  ConfirmSignUpCommand,
  ResendConfirmationCodeCommand,
  AdminDeleteUserCommand,
  AdminGetUserCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SignUpPage.css";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    loginUserId: "",
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showResetButton, setShowResetButton] = useState(false); // ✅ Track Reset Button

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value || "" }));
  };

  const handleSignUp = async () => {
    if (!form.loginUserId || !form.password || !form.email || !form.firstName || !form.lastName) {
      setError("Please fill out all required fields.");
      return;
    }

    let formattedPhone = form.phone;
    if (formattedPhone && !formattedPhone.startsWith("+")) {
      formattedPhone = `+${formattedPhone.replace(/\D/g, "")}`;
    }

    setError("");
    setLoading(true);

    try {
      const client = new CognitoIdentityProviderClient({ region: "us-east-1" });

      // 🔹 Attempt to sign up the user
      const command = new SignUpCommand({
        ClientId: "7883r6l59fuadm2gkgtr4tihve",
        Username: form.loginUserId,
        Password: form.password,
        UserAttributes: [
          { Name: "email", Value: form.email },
          { Name: "name", Value: `${form.firstName} ${form.lastName}` },
          ...(formattedPhone ? [{ Name: "phone_number", Value: formattedPhone }] : []),
        ],
      });

      await client.send(command);
      console.log("✅ Cognito sign-up successful!");

      setSuccessMessage("Sign-up successful! Please check your email for a confirmation code.");
      setIsConfirming(true);
      setShowResetButton(false); // Reset the reset button state
    } catch (err) {
      console.error("❌ Sign-up failed:", err);

      if (err.name === "UsernameExistsException") {
        setError("This email is already registered. You can confirm your account or reset it.");
        setSuccessMessage("Account already exists. Please enter your confirmation code or reset your account.");
        setIsConfirming(true);
        setShowResetButton(true); // ✅ Make sure the reset button appears
      } else {
        setError(err.message || "Sign-up failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };



const handleConfirmAccount = async () => {
  if (!form.loginUserId || !confirmationCode) {
    setError("Please enter your Login ID and Confirmation Code.");
    return;
  }

  setError("");
  setLoading(true);

  try {
    const client = new CognitoIdentityProviderClient({ region: "us-east-1" });

    // 🔹 Confirm Signup
    const confirmCommand = new ConfirmSignUpCommand({
      ClientId: "7883r6l59fuadm2gkgtr4tihve",
      Username: form.loginUserId,
      ConfirmationCode: confirmationCode,
    });

    await client.send(confirmCommand);
    console.log("✅ Account confirmed successfully!");

    // 🚨 Instead of trying to get 'sub', force login 🚨
    setSuccessMessage("Account confirmed! Please log in to complete setup.");
    navigate("/login", { state: { message: "Account confirmed! Please log in." } });
    //navigate("/", { state: { message: "Account confirmed! Please log in." } });

  } catch (err) {
    console.error("❌ Confirmation failed:", err);
    setError(err.message || "Confirmation failed. Please try again.");
  } finally {
    setLoading(false);
  }
};







  const handleResendConfirmationCode = async () => {
    if (!form.loginUserId) {
      setError("Please enter your Login ID to resend the confirmation code.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const client = new CognitoIdentityProviderClient({ region: "us-east-1" });
      const command = new ResendConfirmationCodeCommand({
        ClientId: "7883r6l59fuadm2gkgtr4tihve",
        Username: form.loginUserId,
      });

      await client.send(command);
      setSuccessMessage("Confirmation code resent! Check your email.");
    } catch (err) {
      console.error("❌ Resend failed:", err);
      setError(err.message || "Failed to resend confirmation code.");
    } finally {
      setLoading(false);
    }
  };

  const handleResetAccount = async () => {
    setError("");
    setLoading(true);

    try {
      const client = new CognitoIdentityProviderClient({ region: "us-east-1" });

      // 🔹 Delete the unconfirmed user
      const command = new AdminDeleteUserCommand({
        UserPoolId: "us-east-1_EqRjL9222", // Replace with your Cognito User Pool ID
        Username: form.loginUserId,
      });

      await client.send(command);
      console.log("✅ Account deleted successfully!");

      setSuccessMessage("Your account has been reset. Please sign up again.");
      setIsConfirming(false);
      setShowResetButton(false);
    } catch (err) {
      console.error("❌ Account reset failed:", err);
      setError(err.message || "Failed to reset account. Please contact support.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>{isConfirming ? "Confirm Your Account" : "Sign Up"}</h2>

      <div className="form-group">
        {!isConfirming && (
          <>
            <input type="text" name="loginUserId" placeholder="Choose a Username" value={form.loginUserId} onChange={handleChange} />
            <input type="password" name="password" placeholder="Enter Password" value={form.password} onChange={handleChange} />
            <input type="email" name="email" placeholder="Enter Email" value={form.email} onChange={handleChange} />
            <input type="text" name="firstName" placeholder="First Name" value={form.firstName} onChange={handleChange} />
            <input type="text" name="lastName" placeholder="Last Name" value={form.lastName} onChange={handleChange} />
            <input type="text" name="phone" placeholder="Phone (Optional)" value={form.phone} onChange={handleChange} />
          </>
        )}

        {isConfirming && (
          <>
            <input type="text" name="loginUserId" placeholder="Enter Your Login ID" value={form.loginUserId} onChange={handleChange} />
            <input type="text" name="confirmationCode" placeholder="Enter Confirmation Code" value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} />
          </>
        )}
      </div>

      <div className="button-container">
        {!isConfirming ? (
          <button onClick={handleSignUp} disabled={loading}>Sign Up</button>
        ) : (
          <>
            <button onClick={handleConfirmAccount} disabled={loading}>Confirm Account</button>
            <button onClick={handleResendConfirmationCode} disabled={loading}>Resend Confirmation Code</button>
            {showResetButton && <button onClick={handleResetAccount} disabled={loading}>Reset Account</button>}
          </>
        )}
      </div>

      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default SignUpPage;
