import React from "react";
import ReactDOM from "react-dom/client"; // Import createRoot from ReactDOM client
import App from "./App";

// Use React 18's createRoot API
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
