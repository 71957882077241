import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./CommonStyles.css"; // Common styles for consistency
import { useNavigate } from "react-router-dom";  // ✅ Import navigation

const QAPage = () => {
  const [loginUserId, setLoginUserId] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [question, setQuestion] = useState(null);
  const [answers, setAnswers] = useState({ Answer1: "", Answer2: "", Guess1: "", Guess2: "" });
  const [matchResults, setMatchResults] = useState([]);
  const [matchPartnerResults, setMatchPartnerResults] = useState([]);
  const [partnerResponses, setPartnerResponses] = useState({
    partnerAnswer1: "",
    partnerAnswer2: "",
    partnerGuess1: "",
    partnerGuess2: "",
    comments: [],
  });
  const [partnerFirstName, setPartnerFirstName] = useState("Partner");
  const [error, setError] = useState("");


  // need to kill these two and use of  fields?
  //const [isUserDone, setIsUserDone] = useState(false);
  //const [partnerHasAnswered, setPartnerHasAnswered] = useState(false);

  const [infoMessage, setInfoMessage] = useState("");
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();  // ✅ Initialize navigation

  useEffect(() => {
    handleAuthAndFetchData();
  }, []);

  const handleAuthAndFetchData = () => {
    const idToken = localStorage.getItem("idToken");

    if (!idToken) {
      console.warn("🚨 No idToken found! Redirecting to login...");
      setError("User is not authenticated. Please log in.");
      navigate("/login");  // ✅ Redirect to login
      return;
    }

    try {
      const decodedToken = jwtDecode(idToken);

      if (decodedToken.exp * 1000 < Date.now()) {
        console.warn("🚨 Token expired. Redirecting to login...");
        localStorage.removeItem("idToken");
        setError("Session expired. Please log in again.");
        navigate("/login");  // ✅ Redirect to login
        return;
      }

      const userId = decodedToken.sub;
      const loginUserId = decodedToken["cognito:username"];

      setLoginUserId(loginUserId);
      const campaign = `${userId}_Favorites`;
      setCampaignName(campaign);
      fetchCurrentQuestion(userId, campaign);
    } catch (error) {
      console.error("❌ Error decoding token:", error);
      setError("Authentication error. Please log in again.");
      localStorage.removeItem("idToken");
      navigate("/login");  // ✅ Redirect to login
    }
  };




    useEffect(() => {
      if (!question?.partnerHasSubmitted) {
        setInfoMessage(`${partnerFirstName} has not answered yet.`);
      } else {
        setInfoMessage(""); // Clear the message if the partner has answered
      }
    }, [question?.partnerHasSubmitted, partnerFirstName]); // Runs when these values change



  useEffect(() => {
    if (question?.userHasSubmitted && question?.partnerHasSubmitted) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [question]); //  Only trigger when backend data updates




  const handleMoveOn = async () => {
    try {
      const idToken = localStorage.getItem("idToken");
      const userId = jwtDecode(idToken).sub;

      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "update_campaign_item",
          userid: userId,
          campaignName,
          campaignItemsId: question.campaignItemsId,
          questionHasBeenReviewed: "Y",
        },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );


    //console.log("alookatFetched Data:", question);

    // Check if the partner has submitted
    if (question?.partnerHasSubmitted === false) {
      setInfoMessage(`${question.partnerFirstName || "Your partner"} has not answered yet.`)
    } else {
        setInfoMessage("Marked as reviewed. Moving to the next question.");
    }


        fetchCurrentQuestion(userId, campaignName); // ✅ Load the next question

    } catch (err) {
      setError("Failed to mark question as reviewed.");
      console.error("Review Error:", err);
    }
  };


  const fetchCurrentQuestion = async (userId, campaign) => {
    try {
      const idToken = localStorage.getItem("idToken");
      const response = await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "read_current_item",
          userid: userId,
          campaignName: campaign,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      const data = response.data;
      console.log("Fetched Data:", data);

      if (data) {
        setQuestion(data);

        //setQuestion((prev) => ({ ...prev, ...data }));



        setAnswers({
          Answer1: data.AnswersAndGuesses?.Answer1 || "",
          Answer2: data.AnswersAndGuesses?.Answer2 || "",
          Guess1: data.AnswersAndGuesses?.Guess1 || "",
          Guess2: data.AnswersAndGuesses?.Guess2 || "",
        });
        setMatchResults([data.AnswersAndGuesses]);
        setMatchPartnerResults([data.PartnerResponses]);
        setPartnerResponses(data.PartnerResponses || {});
        setPartnerFirstName(data.partnerFirstName || "Partner");

        // ✅ Keep original logic for tracking partner/user submission status
        const hasPartnerAnswered =
          data.PartnerResponses?.partnerAnswer1 !== "Not yet answered" ||
          data.PartnerResponses?.partnerAnswer2 !== "Not yet answered";

        //setPartnerHasAnswered(hasPartnerAnswered);

        //mjhconst userHasSubmitted = !!data.AnswersAndGuesses?.Answer1 && !!data.AnswersAndGuesses?.Guess1; 
        //mjhsetIsUserDone(userHasSubmitted);
        const userHasSubmitted = data.userHasSubmitted; // ✅ Backend flag replaces frontend check
        const partnerHasSubmitted = data.partnerHasSubmitted; // ✅ Backend flag replaces frontend check

      } else {
        setError("No question data available.");
        setPartnerResponses({});
      }
    } 
    catch (err) {
      console.error("Fetch Error:", err);

      // ✅ Improved error handling with specific messages
      if (err.response) {
        const { status, data } = err.response;

        if (status === 404) {
          if (data.error === "User or partner ID missing.") {
            setError("A required user or partner ID is missing. Please ensure both you and your partner are registered.");
          } else if (data.error === "Campaign list entry not found.") {
            setError("No campaign found. Please start a campaign.");
          } else {
            setError(`Not Found: ${data.error || "Requested data could not be found."}`);
          }
        } else if (status === 400) {
          setError(`Bad Request: ${data.error || "Invalid request. Please check your input."}`);
        } else if (status === 500) {
          setError(`Server Error: ${data.error || "An unexpected error occurred. Please try again later."}`);
        } else {
          setError(`Unexpected Error: ${data.error || "Something went wrong. Please try again."}`);
        }
      } else if (err.request) {
        // Request was made but no response received
        setError("No response received from server. Please check your internet connection.");
      } else {
        // Something else went wrong
        setError(`Unexpected Error: ${err.message || "An unknown error occurred."}`);
      }
    }
  };



  const handleSubmit = async () => {
    try {
      const idToken = localStorage.getItem("idToken");
      const userId = jwtDecode(idToken).sub;

      const payload = {
        operation: "update_campaign_answers",
        userid: userId,
        loginUserId,
        campaignName,
        campaignItemsId: question.campaignItemsId,
        ...answers,
      };

      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        payload,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
//
//      if (partnerHasAnswered) {
//        setIsUserDone(true);
//      }

      setInfoMessage("Answers submitted successfully!"); // Set success message
      fetchCurrentQuestion(userId, campaignName);
    } catch (err) {
      setInfoMessage("Failed to submit answers. Please try again."); // Set error message
      setError("Failed to submit answers.");
      console.error("Submission Error:", err);
    }
  };

  return (
    <div className="container">
      <div className="header">
        <h2>myFavorites List</h2>
        {error && <div className="error-message">{error}</div>}
      </div>
      {loginUserId && question && (
        <div className="card">
          <h3>{question.campaignItemsText}</h3>
          <br />

{/*--- Box 1a--- */}
          <div className="form-group-wrapper">
            <div className="form-group-horizontal">
              <label>Your most favorite:</label>
              <input
                type="text"
                value={answers.Answer1}
                onChange={(e) => setAnswers((prev) => ({ ...prev, Answer1: e.target.value }))}
                disabled={question?.userHasSubmitted && question?.partnerHasSubmitted}
                className={`input-box ${question?.userHasSubmitted && question?.partnerHasSubmitted ? "disabled-input" : ""}`}
              />



{/* --- Box 1b --- */}
              <span className="match-note">
                {(() => {
                  // Both user and partner have submitted (from backend)
                  if (question?.userHasSubmitted && question?.partnerHasSubmitted) {
                    return matchPartnerResults[0]?.Match1
                      ? `${partnerFirstName} got it!` // ✅ Display success if the guess matches
                      : `${partnerFirstName} missed this one! (Guessed: ${partnerResponses.partnerGuess1 || "No guess yet"})`; // ✅ Display failure if the guess doesn't match
                  }
                  // Partner has not submitted yet
                  if (!question?.partnerHasSubmitted) {
                    return ""; // ✅ Placeholder for partner not done case
                  }
                  // Default: no message for other conditions
                  return "";
                })()}
              </span>



            </div>

{/*--- Box 2a--- */}
            <div className="form-group-horizontal">
              <label>Your 2nd favorite:</label>
              <input
                type="text"
                value={answers.Answer2}
                onChange={(e) => setAnswers((prev) => ({ ...prev, Answer2: e.target.value }))}
                disabled={question?.userHasSubmitted && question?.partnerHasSubmitted}
                className={`input-box ${question?.userHasSubmitted && question?.partnerHasSubmitted ? "disabled-input" : ""}`}
              />

{/*--- Box 2b--- */}
            <span className="match-note">
              {(() => {
                // Both user and partner have submitted (from backend)
                if (question?.userHasSubmitted && question?.partnerHasSubmitted) {
                  return matchPartnerResults[0]?.Match2
                    ? `${partnerFirstName} got it!` // ✅ Display success if the guess matches
                    : `${partnerFirstName} missed this one! (Guessed: ${partnerResponses.partnerGuess2 || "No guess yet"})`; // ✅ Display failure if the guess doesn't match
                }
                // Partner has not submitted yet
                if (!question?.partnerHasSubmitted) {
                  return ""; // ✅ Placeholder for partner not done case
                }
                // Default: no message for other conditions
                return "";
              })()}
            </span>


            </div>
            <br /><br />
{/*--- Box 3a--- */}
            <div className="form-group-horizontal">
              <label>{`What do you think is ${partnerFirstName}'s most favorite:`}</label>
              <input
                type="text"
                value={answers.Guess1}
                onChange={(e) => setAnswers((prev) => ({ ...prev, Guess1: e.target.value }))}
                disabled={question?.userHasSubmitted && question?.partnerHasSubmitted}
                className={`input-box ${question?.userHasSubmitted && question?.partnerHasSubmitted ? "disabled-input" : ""}`}
              />

{/*--- Box 3b--- */}
            <span className="match-note">
              {(() => {
                // Both user and partner have submitted (from backend)
                if (question?.userHasSubmitted && question?.partnerHasSubmitted) {
                  return matchResults[0]?.Match1
                    ? "You got it!" //  Display success if the guess matches
                    : `You missed this one. (Guessed: ${partnerResponses.partnerAnswer1 || "No guess yet"})`; //  Corrected template literal syntax
                }
                // Partner has not submitted yet
                if (!question?.partnerHasSubmitted) {
                  return ""; //  Placeholder for partner not done case
                }
                // Default: no message for other conditions
                return "";
              })()}
            </span>



            </div>

{/*--- Box 4a--- */}
            <div className="form-group-horizontal">
              <label>{`What do you think ${partnerFirstName}'s 2nd favorite is:`}</label>
              <input
                type="text"
                value={answers.Guess2}
                onChange={(e) => setAnswers((prev) => ({ ...prev, Guess2: e.target.value }))}
                disabled={question?.userHasSubmitted && question?.partnerHasSubmitted}
                className={`input-box ${question?.userHasSubmitted && question?.partnerHasSubmitted ? "disabled-input" : ""}`}
              />

{/*--- Box 4b--- */}
          <span className="match-note">
            {(() => {
              // Both user and partner have submitted (from backend)
              if (question?.userHasSubmitted && question?.partnerHasSubmitted) {
                return matchResults[0]?.Match2
                    ? "You got it!" //  Display success if the guess matches
                    : `You missed this one. (Guessed: ${partnerResponses.partnerAnswer2 || "No guess yet"})`; //  Corrected template literal syntax
              }
              // Partner has not submitted yet
              if (!question?.partnerHasSubmitted) {
                return ""; //  Placeholder for partner not done case
              }
              // Default: no message for other conditions
              return "";
            })()}
          </span>

            </div>
          </div>

            <br />

            {/* Submit button with info message */}
            <div className="submit-container">
              <br />
              <button
                className="button"
                onClick={handleSubmit}
                disabled={question?.userHasSubmitted && question?.partnerHasSubmitted}
                style={{
                  opacity: question?.userHasSubmitted && question?.partnerHasSubmitted ? 0.6 : 1,
                  cursor: question?.userHasSubmitted && question?.partnerHasSubmitted ? "not-allowed" : "pointer",
                }}
              >
                Submit
              </button>


              {question?.userHasSubmitted && question?.partnerHasSubmitted && question?.questionHasBeenReviewed !== "Y" && (
                <button className="button move-on" onClick={handleMoveOn}>
                  Done
                </button>
              )}




              <br /><br /><br />
              {/* Information message */}
              {infoMessage && (
                <span className="info-message">
                  {infoMessage}
                </span>
              )}
            </div>



        </div>
      )}
    </div>
  );
};

export default QAPage;
