import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import CampaignListPage from "./components/CampaignListPage";
import FetchResultsPage from "./components/FetchResultsPage";
import ListManagerPage from "./components/ListManagerPage";
import ItemManagerPage from "./components/ItemManagerPage";
import CustomerManagerPage from "./components/CustomerManagerPage";
import LoginPage from "./components/LoginPage";
import QAPage from "./components/QAPage"; // Import QAPage
import SignUpPage from "./components/SignUpPage";
import MainStartupPage from "./components/MainStartupPage";
import PersonalRemindersPage from "./components/PersonalRemindersPage"
import CampaignCatalogPage from "./components/CampaignCatalogPage"
import BigQuestionPage from "./components/BigQuestionPage"
import "./App.css";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainStartupPage />} />
        <Route path="/fetch-results/:operation/:campaignName" element={<FetchResultsPage />} />
        <Route path="/list-manager" element={<ListManagerPage />} />
        <Route path="/campaign-manager" element={<ListManagerPage />} />

        

        <Route path="/customer-manager" element={<CustomerManagerPage />} />
        <Route path="/qa/:userid/:campaignName" element={<QAPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />

        <Route path="/item-manager" element={<ItemManagerPage />} />
        <Route path="/item-manager/:campaignName" element={<ItemManagerPage />} />     

        <Route path="/personal-reminders" element={<PersonalRemindersPage />} />
        <Route path="/personal-reminders/:campaignName" element={<PersonalRemindersPage />} />

        <Route path="/campaign-catalog" element={<CampaignCatalogPage />} />
        <Route path="/big-question" element={<BigQuestionPage />} />
        <Route path="/big-question/:campaignName" element={<BigQuestionPage />} />


      </Routes>
    </Router>
  );
};

export default App;
