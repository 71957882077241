import React, { useState } from "react";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  ConfirmSignUpCommand,
  ResendConfirmationCodeCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom"; // ✅ Import useLocation
import { useEffect } from "react";


const LoginPage = () => {

  const location = useLocation(); // ✅ Get state passed from navigate
  const [infoMessage, setInfoMessage] = useState("");



  const navigate = useNavigate();
  const [form, setForm] = useState({
    loginUserId: "",
    password: "",
    newPassword: "",
  });
  const [confirmationCode, setConfirmationCode] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value || "" }));
  };


useEffect(() => {
  if (location.state?.message) {
    setSuccessMessage(location.state.message); // ✅ Set successMessage instead of using a separate variable
  }
}, [location]);




  // ✅ Login Function
const handleLogin = async () => {
  if (!form.loginUserId || !form.password) {
    setError("Please enter your Login ID (username or email) and Password.");
    return;
  }

  setError("");
  setLoading(true);

  try {
    const client = new CognitoIdentityProviderClient({ region: "us-east-1" });
    const command = new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: "7883r6l59fuadm2gkgtr4tihve",
      AuthParameters: {
        USERNAME: form.loginUserId,
        PASSWORD: form.password,
      },
    });

    const response = await client.send(command);
    const idToken = response.AuthenticationResult.IdToken;
    const accessToken = response.AuthenticationResult.AccessToken;

    localStorage.clear();
    const decodedToken = jwtDecode(idToken);
    const userId = decodedToken.sub; // ✅ Cognito-generated UUID



    const fullName = decodedToken.name || ""; // ✅ Get full name from token
    const email = decodedToken.email || "";
    const phone = decodedToken.phone_number || "";

    // ✅ Split first name and last name safely
    const nameParts = fullName.trim().split(" ");
    const firstName = nameParts[0] || ""; 
    const lastName = nameParts.slice(1).join(" ") || "";





    localStorage.setItem("idToken", idToken);
    localStorage.setItem("authToken", accessToken);
    localStorage.setItem("userId", userId);

    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("email", email);
    localStorage.setItem("phone", phone);




    console.log("✅ User logged in. Cognito User ID:", userId);

    // ✅ Step 1: Check if user already exists in the customer database
    const checkUserResponse = await axios.post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        operation: "fetch_customer",
        userid: userId,
      },
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );

    if (checkUserResponse.data && checkUserResponse.data.exists === true) {
      console.log("✅ User already exists in customer database. Skipping add_customer.");
    } else {
      // ✅ Step 2: If user doesn't exist, add them





      await axios.post(
        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        {
          operation: "add_customer",
          userid: userId, // Use the Cognito-generated UUID
          loginUserId: form.loginUserId,
          email: email || "", // Use email from token
          firstName: firstName || "", // First name if available
          lastName: lastName || "", // Last name if available
          phone: phone || "", // Phone if available
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      console.log("✅ New customer added to the database.");
    }

    navigate("/campaign-manager");
  } catch (err) {
    console.error("❌ Login failed:", err);
    if (err.message.includes("User is not confirmed")) {
      setError("Your account is not confirmed. Please confirm your account.");
      setIsConfirming(true);
    } else {
      setError(err.message || "Login failed. Please try again.");
    }
  } finally {
    setLoading(false);
  }
};






  // ✅ Handle "Forgot Password" Request
  const handleForgotPassword = async () => {
    if (!form.loginUserId) {
      setError("Please enter your Login ID to reset your password.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const client = new CognitoIdentityProviderClient({ region: "us-east-1" });
      const command = new ForgotPasswordCommand({
        ClientId: "7883r6l59fuadm2gkgtr4tihve",
        Username: form.loginUserId,
      });

      await client.send(command);
      setIsResetting(true);
      setSuccessMessage("Reset code sent! Check your email.");
    } catch (err) {
      console.error("Forgot password request failed:", err);
      setError(err.message || "Failed to request password reset.");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Handle Resetting Password
  const handleResetPassword = async () => {
    if (!form.loginUserId || !resetCode || !form.newPassword) {
      setError("Please fill in all fields.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const client = new CognitoIdentityProviderClient({ region: "us-east-1" });
      const command = new ConfirmForgotPasswordCommand({
        ClientId: "7883r6l59fuadm2gkgtr4tihve",
        Username: form.loginUserId,
        ConfirmationCode: resetCode,
        Password: form.newPassword,
      });

      await client.send(command);

      setSuccessMessage("Password reset successfully! Please log in.");
      setIsResetting(false);
    } catch (err) {
      console.error("Reset failed:", err);
      setError(err.message || "Password reset failed.");
    } finally {
      setLoading(false);
    }
  };

  // ✅ Handle Confirming Account
  const handleConfirmAccount = async () => {
    if (!form.loginUserId || !confirmationCode) {
      setError("Please enter your Login ID and Confirmation Code.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      const client = new CognitoIdentityProviderClient({ region: "us-east-1" });
      const command = new ConfirmSignUpCommand({
        ClientId: "7883r6l59fuadm2gkgtr4tihve",
        Username: form.loginUserId,
        ConfirmationCode: confirmationCode,
      });

      await client.send(command);
      setSuccessMessage("Account confirmed successfully! Please log in.");
      setIsConfirming(false);
    } catch (err) {
      console.error("Confirmation failed:", err);
      setError(err.message || "Confirmation failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>{isConfirming ? "Confirm Your Account" : isResetting ? "Reset Password" : "Login"}</h2>

      <div className="form-group">
        <input
          type="text"
          name="loginUserId"
          placeholder="Enter Login ID"
          value={form.loginUserId}
          onChange={handleChange}
        />

        {!isConfirming && !isResetting && (
          <input
            type="password"
            name="password"
            placeholder="Enter Password"
            value={form.password}
            onChange={handleChange}
          />
        )}

        {isConfirming && (
          <>
            <input
              type="text"
              name="confirmationCode"
              placeholder="Enter Confirmation Code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </>
        )}

        {isResetting && (
          <>
            <input
              type="text"
              name="resetCode"
              placeholder="Enter Reset Code"
              value={resetCode}
              onChange={(e) => setResetCode(e.target.value)}
            />
            <input
              type="password"
              name="newPassword"
              placeholder="Enter New Password"
              value={form.newPassword}
              onChange={handleChange}
            />
          </>
        )}
      </div>

      <div className="button-container">
        {!isConfirming && !isResetting ? (
          <>
            <button onClick={handleLogin} disabled={loading}>Login</button>
            <button onClick={handleForgotPassword} disabled={loading}>Forgot Password?</button>
            <button onClick={() => navigate("/signup")}>Sign Up</button>
            <button onClick={() => setIsConfirming(true)}>Confirm Account</button>
          </>
        ) : isConfirming ? (
          <>
            <button onClick={handleConfirmAccount} disabled={loading}>Confirm Account</button>
            <button onClick={() => setIsConfirming(false)}>Back to Login</button>
          </>
        ) : (
          <>
            <button onClick={handleResetPassword} disabled={loading}>Reset Password</button>
            <button onClick={() => setIsResetting(false)}>Back to Login</button>
          </>
        )}
      </div>

      {loading && <div>Loading...</div>}
      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}



    </div>
  );
};

export default LoginPage;
