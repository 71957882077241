import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Ensure jwt-decode is installed
import "./ItemManagerPage.css"; // Ensure this contains styling for layout
import { useNavigate, useParams } from "react-router-dom";

const ItemManagerPage = () => {
  const [campaigns, setCampaigns] = useState([]); // Campaign dropdown
  const [items, setItems] = useState([]); // Items table
  const [form, setForm] = useState({
    campaignItemsId: "",
    campaignItemsType: "",
    campaignItemsAuthor: "",
    campaignItemsName: "",
    campaignItemsText: "",
    campaignItemsURL1: "",
  });
  //const [selectedCampaign, setSelectedCampaign] = useState("");
  const [isQA, setIsQA] = useState(false); // Track if QA campaign
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [userId, setUserId] = useState(""); // Dynamically resolved user ID
  const [nextItem, setNextItem] = useState(null); // Store the next item details
  const [cognitoUserId, setCognitoUserId] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const { campaignName } = useParams();
  const [selectedCampaign, setSelectedCampaign] = useState(campaignName || "");
  const [campaignType, setCampaignType] = useState(""); // Track campaign type
  const isReadOnly = campaignType === "Favorites"; // Read-only check

const navigate = useNavigate();

useEffect(() => {
  const idToken = localStorage.getItem("idToken");

  if (!idToken) {
    console.warn("🚨 No idToken found! Redirecting to login...");
    setError("User is not authenticated. Please log in.");
    navigate("/login");
    return;
  }

  try {
    const decodedToken = jwtDecode(idToken);
    console.log("✅ Decoded Token:", decodedToken);

    if (decodedToken.exp * 1000 < Date.now()) {
      console.warn("🚨 Token expired. Redirecting to login...");
      localStorage.removeItem("idToken");
      setError("Session expired. Please log in again.");
      navigate("/login");
      return;
    }

    setUserId(decodedToken.sub);
    fetchAllCampaigns(decodedToken.sub);



    // ✅ Auto-fetch items if a campaignName is in the URL
    if (campaignName) {
      fetchItems(campaignName);
    }




  } catch (error) {
    console.error("❌ Error decoding token:", error);
    setError("Authentication error. Please log in again.");
    localStorage.removeItem("idToken");
    navigate("/login");
  }
}, []);

const handleAuthError = (err) => {
  console.error("❌ API Request Failed:", err);

  if (err.response && err.response.status === 401) {
    console.warn("🚨 Unauthorized! Redirecting to login...");
    localStorage.removeItem("idToken");
    setError("Session expired. Please log in again.");
    navigate("/login");
  } else {
    setError("An error occurred. Please try again.");
  }
};

// Ensure Authorization Token is included in API requests
const fetchAllCampaigns = async (userid) => {
  setLoading(true);
  setError("");
  try {
    const response = await axios.get(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      {
        params: { operation: "fetch_all", userid },
        headers: { Authorization: `Bearer ${localStorage.getItem("idToken")}` },
      }
    );

    if (response.data.message === "No campaigns found") {
      setCampaigns([]);
    } else {
      const data = Array.isArray(response.data) ? response.data : [response.data];
      setCampaigns(data);
    }
  } catch (err) {
    handleAuthError(err);
  } finally {
    setLoading(false);
  }
};


const determineCampaignType = (name) => {
  if (name.includes("_Favorites")) return "Favorites";
  if (name.includes("_PersonalReminders")) return "PersonalReminders";
  if (name.includes("_CommercialReminders")) return "CommercialReminders";
  if (name.includes("_BigQuestion")) return "BigQuestion";
  if (name.includes("_DailyReminders")) return "DailyReminders";
  return "";
};





const fetchItems = (campaignName) => {
  const idToken = localStorage.getItem("idToken");
  const decodedToken = jwtDecode(idToken);
  const userId = decodedToken.sub;

  setLoading(true);

  const payload = {
    operation: "fetch_campaign_items",
    userid: userId,
    campaignName: campaignName,
  };

  axios
    .post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      payload,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    )
    .then((response) => {
      console.log("📡 Items fetched successfully:", response.data);
      setItems(response.data);
      setLoading(false);

      // ✅ Detect campaign type and store it
      const campaignType = determineCampaignType(campaignName);
      setCampaignType(campaignType);
    })
    .catch((err) => {
      console.error("❌ Error fetching items:", err);
      setError("Failed to load items.");
      setLoading(false);
    });
};









  // Handle campaign selection
  const handleCampaignChange = (e) => {
    const campaignName = e.target.value;
    setSelectedCampaign(campaignName);
    setIsQA(campaignName.includes("_Favorites"));
    fetchItems(campaignName);
  };

  // Fetch next item
  const handleReadNext = () => {

    const idToken = localStorage.getItem("idToken");
    const decodedToken = jwtDecode(idToken);
    const userId = decodedToken.sub; 


    const payload = {
      operation: "read_next_item",
      userid: userId,
      campaignName: selectedCampaign,
    };

    axios
      .post(


        "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
        payload,
        {
          headers: {
            Authorization: `Bearer ${idToken}`, // ✅ Added token here
          },
        }


      )
      .then((response) => {
        setNextItem(response.data);
      })
      .catch((err) => {
        console.error("Error fetching next item:", err);
        setError("Failed to fetch the next item.");
      });
  };



  // Handle form updates
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    
    // Ensure the form is editable without immediately switching to update mode
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value
    }));
  };




  // Submit form (Add or Update)
const handleSubmit = (isUpdate = false) => {
  if (!userId) {
    setError("User ID not found. Please log in.");
    return;
  }


  const token = localStorage.getItem("idToken");
  if (!token) {
    console.error("🚨 No token found. Redirecting to login...");
    setError("User is not authenticated. Please log in.");
    //navigate("/login");
    return;
  }



  const payload = {
    operation: isUpdate ? "update_campaign_item" : "add_campaign_item",
    userid: userId,
    campaignName: selectedCampaign,
    ...(isUpdate && { campaignItemsId: parseInt(form.campaignItemsId, 10) }),
    campaignItemsType: form.campaignItemsType,
    campaignItemsAuthor: form.campaignItemsAuthor,
    campaignItemsName: form.campaignItemsName,
    campaignItemsText: form.campaignItemsText,
    campaignItemsURL1: form.campaignItemsURL1,
  };

  axios
    .post(
      "https://gwbtdh9454.execute-api.us-east-1.amazonaws.com/prod/campaign",
      payload,

      {
        headers: {
          Authorization: `Bearer ${token}`, // ✅ Added token here
        },
      }



    )
    .then((response) => {
      //alert(response.data.message);
      fetchItems(selectedCampaign); // ✅ Refresh the list

      // ✅ Reset the form so "Add" mode is available again
      setForm({
        campaignItemsId: "",
        campaignItemsType: "",
        campaignItemsAuthor: "",
        campaignItemsName: "",
        campaignItemsText: "",
        campaignItemsURL1: "",
      });
    })
    .catch((err) => {
      console.error("Error saving data:", err);
      alert("Failed to save data.");
    });
};








  return (
    <div className="container">
      <h2>Manage Campaign Items</h2>

      {error && <div className="error">{error}</div>}

      {/* Campaign Dropdown */}
      <div className="dropdown-container">
        <label>Select Campaign:</label>
        <select value={selectedCampaign} onChange={handleCampaignChange}>
          <option value="">-- Select Campaign --</option>


            {campaigns
              .filter((c) =>
                c.campaignListCampaignType === "PersonalReminders" ||
                c.campaignListCampaignType === "CommercialReminders"
              )
              .map((c) => (
                <option key={c.campaignName} value={c.campaignName}>
                  {c.campaignName.replace(`${userId}_`, "")}
                </option>
            ))}




        </select>
      </div>






      {/* Items Table */}
      <div className="items-table-container">
        {loading ? (
          <div>Loading items...</div>
        ) : (
          <table className="items-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Type</th>
                <th>Author</th>
                <th>Name</th>
                <th>Text</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.campaignItemsId}>
                  <td>{item.campaignItemsId}</td>
                  <td>{item.campaignItemsType}</td>
                  <td>{item.campaignItemsAuthor}</td>
                  <td>{item.campaignItemsName}</td>
                  <td>{item.campaignItemsText}</td>
                  <td>
                    {!isReadOnly && (
                      <button onClick={() => setForm({ ...item, campaignItemsId: item.campaignItemsId })}>
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* Beg of add/edit section */}


{/* Add/Update Form */}
{!isReadOnly && ( // ✅ Wrap entire section in conditional check
  <div className="form-container">
    <h3>{form.campaignItemsId ? "Update Item" : "Add Item"}</h3>
    
    <input
      name="campaignItemsId"
      placeholder="ID (Auto-Generated)"
      value={form.campaignItemsId || ""}
      onChange={handleFormChange}
      disabled // 🚀 This prevents manual input
    />

    <input
      name="campaignItemsType"
      placeholder="Type"
      value={form.campaignItemsType || ""}
      onChange={handleFormChange}
    />
    <input
      name="campaignItemsAuthor"
      placeholder="Author"
      value={form.campaignItemsAuthor || ""}
      onChange={handleFormChange}
    />
    <input
      name="campaignItemsName"
      placeholder="Name"
      value={form.campaignItemsName || ""}
      onChange={handleFormChange}
    />
    <input
      name="campaignItemsText"
      placeholder="Text"
      value={form.campaignItemsText || ""}
      onChange={handleFormChange}
    />
    <input
      name="campaignItemsURL1"
      placeholder="URL"
      value={form.campaignItemsURL1 || ""}
      onChange={handleFormChange}
    />

    <button onClick={() => handleSubmit(!!form.campaignItemsId)}>
      {form.campaignItemsId ? "Update" : "Add"}
    </button>
  </div>
)}
      {/* End of add/edit section */}


    <br /><br />
      {/* Read Next Button */}
      <button onClick={handleReadNext}>Read Next Item</button>



    {/* Next Item Table (Styled to Match Items Table) */}
    <div className="items-table-container">
      {nextItem && nextItem.length > 0 ? (
        <table className="items-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Author</th>
              <th>Name</th>
              <th>Text</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {nextItem.map((item) => (
              <tr key={item.campaignItemsId}>
                <td>{item.campaignItemsId}</td>
                <td>{item.campaignItemsType || "N/A"}</td>
                <td>{item.campaignItemsAuthor || "N/A"}</td>
                <td>{item.campaignItemsName || "N/A"}</td>
                <td>{item.campaignItemsText || "N/A"}</td>
                <td>
                  {item.url1 ? (
                    <a href={item.url1} target="_blank" rel="noopener noreferrer">
                      Open Link
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>No next items available.</div>
      )}

    <br /><br />
    </div>







</div>




  );

};

export default ItemManagerPage;
